import React, {useState} from 'react';
import './app.scss';
import {GamesList} from "./components/games-list";

export type Game = {
    id: number;
    title: string;
    image: string;
    minNumberOfPlayers: number;
    maxNumberOfPlayers: number | null;
    minTime: number | null;
    maxTime: number | null;
    minAge: number | null;
    maxAge: number | null;
    status: 'available' | 'unavailable';
}

function App() {
  return (
    <div className="app">
        <GamesList />
    </div>
  );
}

export default App;
