import {Game} from "../App";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsers} from "@fortawesome/free-solid-svg-icons";

export type PlayersChipProps = {
    game: Game,
}

export const PlayersChip = (props: PlayersChipProps) => {
    const { game } = props;
    const { minNumberOfPlayers, maxNumberOfPlayers } = game;

    if (!minNumberOfPlayers && !maxNumberOfPlayers) return null;
    return (
        <div className={'gm-chip gm-players-chip'}>
            <FontAwesomeIcon icon={faUsers} />
            { minNumberOfPlayers && maxNumberOfPlayers && minNumberOfPlayers === maxNumberOfPlayers && `${minNumberOfPlayers}`}
            { minNumberOfPlayers && maxNumberOfPlayers && minNumberOfPlayers !== maxNumberOfPlayers && `${minNumberOfPlayers}-${maxNumberOfPlayers}`}
            { minNumberOfPlayers && !maxNumberOfPlayers && `${minNumberOfPlayers}+`}
        </div>
    )
}