import {PlayersChip} from "./players-chip";
import {TimeChip} from "./time-chip";
import {AgeChip} from "./age-chip";
import React, {useLayoutEffect, useRef, useState} from "react";
import games from "../games.json";
import {Game} from "../App";
import {SearchBar} from "./search-bar";

export const GamesList = () => {
    const [selectedGame, setSelectedGame] = useState<Game>();

    // @ts-ignore
    const gamesList : Game[] = games;

    const onGameClick = (e: React.MouseEvent<HTMLDivElement>, game: Game) => {
        setSelectedGame(game);
    }

    return (
        <div className={'gm-container'}>
            {/*<div className={'gm-filters'}>*/}
            {/*    <SearchBar />*/}
            {/*</div>*/}
            <div className={'gm-list'}>
                {gamesList.map(game => (
                    <div className={['gm-card', 'gm-'+game.status, selectedGame?.id === game.id ? 'active' : ''].join(' ')} onClick={(e) => onGameClick(e, game)}>
                        <div className={'gm-image-container'}>
                            <img className={'gm-image'} src={game.image} alt={game.title} />
                        </div>
                        <div className={'gm-chips'}>
                            <PlayersChip game={game} />
                            <TimeChip game={game} />
                            <AgeChip game={game} />
                        </div>
                        <div className={'gm-title'}>
                            <div className={'gm-title-text'}>
                                {game.title}
                            </div>
                        </div>
                        {game.status === 'unavailable' && (<div className={'unavailable-label'}>Indisponibil momentan</div>)}
                    </div>
                ))}
            </div>
        </div>
    )
}