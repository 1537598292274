import {Game} from "../App";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-solid-svg-icons";

export type TimeChipProps = {
    game: Game,
}

export const TimeChip = (props: TimeChipProps) => {
    const { game } = props;
    const { minTime, maxTime } = game;

    if (!minTime && !maxTime) return null;
    return (
        <div className={'gm-chip gm-time-chip'}>
            <FontAwesomeIcon icon={faClock} />
            { minTime && maxTime && minTime === maxTime && `${minTime} min`}
            { minTime && maxTime && minTime !== maxTime && `${minTime}-${maxTime} min`}
            { minTime && !maxTime && `${minTime}+ min`}
            { !minTime && maxTime && `sub ${maxTime} min`}
        </div>
    )
}