import {Game} from "../App";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChild} from "@fortawesome/free-solid-svg-icons";

export type AgeChipProps = {
    game: Game,
}

export const AgeChip = (props: AgeChipProps) => {
    const { game } = props;
    const { minAge, maxAge } = game;

    if (!minAge && !maxAge) return null;
    return (
        <div className={'gm-chip gm-age-chip'}>
            <FontAwesomeIcon icon={faChild} />
            { minAge && maxAge && minAge === maxAge && `${minAge} ani`}
            { minAge && maxAge && minAge !== maxAge && `${minAge}-${maxAge} ani`}
            { minAge && !maxAge && `${minAge}+ ani`}
            { !minAge && maxAge && `sub ${maxAge} ani`}
        </div>
    )
}